import { forEachObjIndexed } from 'ramda';

// same as @ci/utils version, only extended with `shouldKeepEmptyValues` parameter
export const serializeQueryParams = (params: object, shouldKeepEmptyValues: boolean) => {
	const queryParams = new URLSearchParams();
	forEachObjIndexed((value, key) => {
		if (value || shouldKeepEmptyValues) {
			queryParams.append(key, value ?? '');
		}
	}, params);

	return queryParams.toString();
};
