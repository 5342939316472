const PADDING_CHAR = '=';

/**
 * @description Same purpose as native `atob`, but unlike `atob`, handling UTF-8 characters like `Ā` or `Ņ` correctly
 * Also adds padding `=`s to the end of input string (if missing) - some versions of Hermes don't handle unpadded base64
 * source: https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * @param input base64 encoded string
 */
export const decodeBase64 = (input: string) => {
	const potentialPaddingLength = 4 - (input.length % 4);
	const padding =
		!input.endsWith(PADDING_CHAR) && potentialPaddingLength <= 2 // max 2 `=` chars as padding by base64 definition
			? PADDING_CHAR.repeat(potentialPaddingLength)
			: '';
	const paddedInput = input + padding;

	const parsedInput = atob(paddedInput);
	const bytes = new Uint8Array(parsedInput.length);

	for (let i = 0; i < parsedInput.length; i++) {
		bytes[i] = parsedInput.charCodeAt(i);
	}

	const decoder = new TextDecoder();
	return decoder.decode(bytes);
};
