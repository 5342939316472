module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AS Kredītinformācijas Birojs","short_name":"MyCI","start_url":"/","background_color":"#ffffff","theme_color":"#dc3427","display":"standalone","icon":"D:\\BuildAgent02\\work\\3584ffa7ff58f781\\libs\\assets\\images\\lva_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2d1085438a1f098b65fe7ca83d4d435c"},
    },{
      plugin: require('../../../../libs/gatsby-plugin-setup/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"en-GB","distributionPath":"D:\\BuildAgent02\\work\\3584ffa7ff58f781\\apps\\build\\fo","environment":{"baseFilePath":"D:\\BuildAgent02\\work\\3584ffa7ff58f781\\.env.development"},"sassData":{"env-myci-instance":"lva"}},
    },{
      plugin: require('../../../../libs/gatsby-plugin-redux/gatsby-browser.js'),
      options: {"plugins":[],"applicationType":"FO"},
    },{
      plugin: require('../../../../libs/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"en-GB","preloadNamespaces":["myci","myciAuth","atoms","errorMessage"]},
    },{
      plugin: require('../../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
